<template>
    <div class="content">
        <el-form :model="mdfPwdForm" status-icon :rules="rulesMdfPwd" ref="mdfPwdForm" key="mdfPwdForm"
                 label-position="top" label-width="80px">
            <el-form-item label="目前的密碼" prop="pass">
                <el-input type="password" v-model="mdfPwdForm.pass"></el-input>
            </el-form-item>
            <el-form-item label="新密碼" prop="newPass">
                <el-input type="password" v-model="mdfPwdForm.newPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="確認新密碼" prop="checkPass">
                <el-input type="password" v-model="mdfPwdForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('mdfPwdForm')">確定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {MdfUserPwd} from '@/request/api';
    export default {
        components: {},
        data() {
            var checkPass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                    return;
                }
                callback();
            };

            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    if (this.mdfPwdForm.checkPass !== '') {
                        this.$refs.mdfPwdForm.validateField('checkPass');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('請再次輸入密碼'));
                } else if (value !== this.mdfPwdForm.newPass) {
                    callback(new Error('兩次輸入密碼不一致!'));
                } else {
                    callback();
                }
            };
            return {
                userLoginType: '',
                mdfPwdForm: {
                    pass: '',
                    newPass: '',
                    checkPass: ''
                },
                rulesMdfPwd: {
                    pass: [
                        {required: true, validator: checkPass, trigger: 'blur'}
                    ],
                    newPass: [
                        {required: true, validator: validatePass, trigger: 'blur'}
                    ],
                    checkPass: [
                        {required: true, validator: validatePass2, trigger: 'blur'}
                    ],
                }
            };
        },
        methods: {
            submitForm(formName) {
                let _this = this;
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        this.$message.error('請輸入正確的資料');
                        return false;
                    }
                    MdfUserPwd({
                        password: this.mdfPwdForm.pass
                        , newPassword: this.mdfPwdForm.newPass
                        , checkPassword: this.mdfPwdForm.checkPass
                    }).then(res => {
                        if (res.code == 200) {//取得line授权网址并跳转
                            localStorage.removeItem("magor3-auth");
                            _this.$message.success("修改成功，請重新登入!");
                            _this.$router.push('/login');
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(e => {
                        this.$message.error(e.message);
                        console.log(e);
                    });
               });
            }
        }
    }
</script>
<style lang="less" scoped>
    .content{
        padding: 10px;
    }
</style>
